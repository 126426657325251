import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
    Directive,
    TemplateRef,
    ContentChild,
    forwardRef,
} from '@angular/core';
import { TranslateService } from '../../translation/translate.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectComponent } from '../select/select.component';

@Directive({
    selector: '[selectComponentOption]',
})
export class SelectComponentOptionTemplateDirective<T> {
    constructor(public readonly template: TemplateRef<{ $implicit: T }>) {}
}

@Component({
    selector: 'select-component',
    templateUrl: 'select.component.html',
    styleUrls: ['select.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true }],
})
export class OldSelectComponent<T> {
    show = false;
    options: Array<any>;
    actionSelect = false;
    choosen: Array<any> = [];
    checkbox = false;
    placeholder = '';
    itemsWithHeaders: Array<{
        header: string;
        values: Array<string>;
    }> = [];

    textSearch = '';

    @ContentChild(SelectComponentOptionTemplateDirective, { static: true })
    optionTemplate: SelectComponentOptionTemplateDirective<T>;
    @ViewChild('textInput') textInput: ElementRef;
    @Input('label') label = '';
    @Input('sort') sort = false;
    @Input('showValuesOnCheckbox') showValuesOnCheckbox = false;
    @Input('limitOfValuesOnCheckbox') limitOfValuesOnCheckbox = 0;
    @Input() translationArgument: string;
    @Input('translate') public translate = false;
    @Input() containerPlace = 'left';
    @Input() uppercase = true;
    @Output('getChoosen') public getChoosen = new EventEmitter<Array<string>>();

    constructor(
        private _eref: ElementRef,
        private changeRef: ChangeDetectorRef,
        private translateService: TranslateService
    ) {}

    @Input('checkbox') set isCheckbox(checkbox: boolean) {
        this.checkbox = checkbox;
    }

    @Input('options') set optionsArray(options: Array<string>) {
        this.options = options;
        this.changeRef.detectChanges();
        this.setContainerMinWidth();
    }

    @Input('choosen') set choosenArray(choosen: Array<string>) {
        this.choosen = choosen;
    }

    @Input('placeholder') set actualPlaceholder(placeholder: string) {
        this.placeholder = placeholder;
    }

    @Input('actionSelect') set isActionSelected(actionSelect: boolean) {
        this.actionSelect = actionSelect;
    }

    @HostListener('document:click', ['$event']) clickedOutside($event) {
        if (!this._eref.nativeElement.contains($event.target)) {
            // or some similar check
            this.show = false;
        }
    }

    public showDropdown(): boolean {
        return (this.itemsWithHeaders && this.itemsWithHeaders.length > 0) || (this.options && this.options.length > 0);
    }

    public clickInside() {
        if (!this.show) {
            this.focusElement();
        }
        this.show = this.checkbox ? true : !this.show;
    }

    public choose(option: string) {
        if (this.checkbox) {
            const optionPosition = this.choosen.indexOf(option);
            if (optionPosition === -1) {
                this.choosen.push(option);
            } else {
                this.choosen.splice(optionPosition, 1);
            }
        } else {
            this.choosen[0] = option;
        }
        this.getChoosen.emit(this.choosen);
    }

    public shouldShowValuesOnCheckbox(): boolean {
        return this.showValuesOnCheckbox && this.choosen && this.choosen.length <= this.limitOfValuesOnCheckbox;
    }

    private focusElement() {
        setTimeout(() => {
            if (this.textInput && this.textInput.nativeElement) {
                this.textInput.nativeElement.focus();
            }
        }, 50);
    }

    private setContainerMinWidth(): void {
        const element = document.getElementById('select-component-label' + this.label);
        const container = document.getElementById(this.placeholder);
        if (this.label && element && container) {
            container.style.minWidth = element.offsetWidth + 'px';
        }
    }

    getTranslatedLabel(chosen: Array<any>) {
        return chosen.map(identifier => this.translateService.get([identifier]));
    }
}
