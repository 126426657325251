import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrayToStringPipe } from './pipes/array-to-string.pipe';
import { CapitalizePipe } from './pipes/capitalize-pipe';
import { CommasInInputPipe } from './pipes/commas-in-input-pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { MultipleOrderByPipe } from './pipes/multiple-order-by.pipe';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { RemoveCamelcasePipe } from './pipes/remove-camelcase.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { DownloadFileDirective } from './directives/download-file.directive';
import { ForceOneLineDirective } from './directives/force-one-line.directive';
import { FileDragDirective } from './directives/file-drag.directive';
import { OnlyNumberInputDirective } from './directives/only-number-input.directive';
import { SetHeightDirective } from './directives/set-height.directive';
import { SetMaxHeightDirective } from './directives/set-max-height.directive';
import { InfiniteScrollerDirective } from './directives/infinite-scroller.directive';
import { ButtonDisablerDirective } from './directives/button-disabler.directive';
import { TextareaResizeDirective } from './directives/textarea-resize.directive';
import { AutocompleteDisableDirective } from './directives/autocomplete-disable.directive';
import { TransitionHeightDirective } from './directives/transition-height.directive';
import { ArrowsDirective } from './directives/arrows-directive';
import { TranslationDirective } from './directives/translation.directive';
import { HrefDirective } from './directives/href.directive';
import { RemoveBackgroundOverflowDirective } from './directives/remove-background-overflow.directive';
import { ClickedOutsideDirective } from './directives/clicked-outside.directive';
import { EscPressedDirective } from './directives/esc-pressed.directive';
import { BackgroundImageDirective } from './directives/background-image.directive';
import { NotPipe } from './pipes/not.pipe';
import { LogPipe } from './pipes/log.pipe';
import { CommasInAmountPipe } from './pipes/commas-in-amount';
import { UtcDatePipe } from './pipes/utc-date.pipe';
import { DisallowCommaDirective } from './directives/disallow-comma.directive';

const EXPORTED_DECLARATIONS = [
    OrderByPipe,
    ArrayToStringPipe,
    CapitalizePipe,
    CommasInInputPipe,
    FileSizePipe,
    FilterPipe,
    MultipleOrderByPipe,
    OrderByDatePipe,
    OrderByDatePipe,
    RemoveCamelcasePipe,
    SafeHtmlPipe,
    DownloadFileDirective,
    ForceOneLineDirective,
    FileDragDirective,
    OnlyNumberInputDirective,
    SetHeightDirective,
    SetMaxHeightDirective,
    InfiniteScrollerDirective,
    ButtonDisablerDirective,
    TextareaResizeDirective,
    AutocompleteDisableDirective,
    TransitionHeightDirective,
    ArrowsDirective,
    TranslationDirective,
    HrefDirective,
    RemoveBackgroundOverflowDirective,
    ClickedOutsideDirective,
    EscPressedDirective,
    BackgroundImageDirective,
    NotPipe,
    LogPipe,
    CommasInAmountPipe,
    UtcDatePipe,
    DisallowCommaDirective,
];

@NgModule({
    imports: [CommonModule],
    declarations: EXPORTED_DECLARATIONS,
    exports: EXPORTED_DECLARATIONS,
})
export class UtilsModule {}
