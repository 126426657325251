/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FondaApiService } from './api/fonda-api.service';
import { AuthService } from './auth/auth.service';
import { CookiesModule } from './cookies/cookies.module';
import { LogoutComponent } from './logout';
import { PACKAGE_MANAGER_API_KEY } from './package-manager-api-key';
import { RedirectionService } from './services/redirection-service';
import { ToasterService } from 'angular2-toaster';
import { AuthenticationGuard } from './auth/authentication.guard';
import { ApplicantGuard } from './auth/applicant.guard';
import { CaseworkerGuard } from './auth/caseworker.guard';
import { InProgressHttpInterceptor } from './api/in-progress.http-interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FondaApiGlobalVariables } from './api/fonda-api-global-variables';
import { FileService } from './services/file.service';
import { SharedModule } from './shared/shared.module';
import { EventsHandlerService } from './services/events-handler-service';
import { LocalStorageService } from './services/local-storage-service';
import { AutoLogoutService } from './services/auto-logout-service';
import { AdministrationGuard } from './auth/administration.guard';
import { PaymentStoreService } from './services/payment-store-service';
import { PdfFileCreator } from './services/pdf-file-creator';
import { BackendGuard } from './auth/backend.guard';
import { PdfApplicationService } from './pdf-application/pdf-application-service';
import { ZipService } from './services/zip/zip-service';
import { DOMService } from './services/dom-service';
import { FilesConverterPopupService } from './components/files-converter-popup/files-converter-popup-service';
import { PdfListService } from './pdf-application/pdf-list-service';
import { ApplicantCaseOverviewPopupService } from './components/applicant-case-overview-popup/applicant-case-overview-popup.service';
import { CorrespondencePdfService } from './services/correspondence-pdf-service';
import { TranslationModule } from './shared/translation/translation.module';
import { PopupModule } from './shared/popup/popup.module';
import { SiteSettingsService } from './services/site-settings.service';
import { TOKEN_HTTP_INTERCEPTOR_PROVIDER } from './api/token.http-interceptor';
import { ImpersonatorModule } from './impersonator/impersonator.module';
import { LandingPageGuard } from './auth/landing-page.guard';
import { LandingPageRedirectionService } from './auth/landing-page-redirection.service';
import { API_BASE_URL } from './api/api-base-url';
import { NgxSlackModule } from '@linkfactory/ngx-slack';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { DOWN_FOR_MAINTENANCE_INTERCEPTOR_PROVIDER } from './api/down-for-maintenance.http-interceptor';
import { AppLoadGuard } from './app-load.guard';
import { NATIVE_LOCATION } from './native-location';
import { UserChatWidgetModule } from './user-chat-widget/user-chat-widget.module';
import { DirectLoginComponent } from './components/direct-login/direct-login.component';
import { DirectLoginGuard } from './components/direct-login/direct-login.guard';
import { IePopupComponent } from './components/ie-popup/ie-popup.component';
import { RecoverPasswordRedirectComponent } from './components/recover-password-redirect/recover-password-redirect.component';
import { DatepickerModule } from 'ng2-datepicker';
import { CurrencyService } from './shared/currency/currency.service';

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (environment.ngrxLog) {
            if (environment.ngrxLog.state) console.log('[before]', state);
            if (environment.ngrxLog.actions) console.log('[action]', action);
            const newState = reducer(state, action);
            if (environment.ngrxLog.state) console.log('[after]', newState);
            console.log('------------------');

            return newState;
        }

        return reducer(state, action);
    };
}

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        DatepickerModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule.forRoot(),
        TranslationModule.forRoot(),
        PopupModule,
        ImpersonatorModule,
        StoreModule.forRoot(
            {},
            {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                    strictStateSerializability: true,
                    strictActionSerializability: true,
                },
                metaReducers: [debug],
            }
        ),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        CookiesModule,
        UserChatWidgetModule,
    ],
    declarations: [
        AppComponent,
        DirectLoginComponent,
        IePopupComponent,
        RecoverPasswordRedirectComponent,
        LogoutComponent,
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: NATIVE_LOCATION, useValue: window.location },
        { provide: API_BASE_URL, useValue: (<any>window).FONDA_API_URL },
        { provide: PACKAGE_MANAGER_API_KEY, useValue: (<any>window).FONDA_PACKAGE_MANAGER_API_KEY },
        FondaApiService,
        AutoLogoutService,
        AuthService,
        RedirectionService,
        ToasterService,
        AuthenticationGuard,
        BackendGuard,
        PdfListService,
        ApplicantGuard,
        CaseworkerGuard,
        AdministrationGuard,
        { provide: HTTP_INTERCEPTORS, useClass: InProgressHttpInterceptor, multi: true },
        DOWN_FOR_MAINTENANCE_INTERCEPTOR_PROVIDER,
        TOKEN_HTTP_INTERCEPTOR_PROVIDER,
        FondaApiGlobalVariables,
        LocalStorageService,
        FileService,
        EventsHandlerService,
        PaymentStoreService,
        PdfFileCreator,
        PdfApplicationService,
        ZipService,
        DOMService,
        FilesConverterPopupService,
        ApplicantCaseOverviewPopupService,
        CorrespondencePdfService,
        SiteSettingsService,
        LandingPageRedirectionService,
        LandingPageGuard,
        AppLoadGuard,
        DirectLoginGuard,
        CurrencyService,
    ],
})
export class AppModule {
    constructor(private siteSettings: SiteSettingsService, private currencyService: CurrencyService) {
        if (!(<any>window).FONDA_API_URL || !(<any>window).FONDA_PACKAGE_MANAGER_API_KEY) {
            throw new Error(
                'Missing Configuration.js file that creates window.FONDA_API_KEY and window.FONDA_PACKAGE_MANAGER_API_KEY'
            );
        }
    }
}
